import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Electron and React integration done right",
  "author": "Patrick Passarella",
  "date": "2020-12-13",
  "subtitle": "Creating an updated Electron + React app, learning how to communicate between them, and how to build for cross-platform",
  "cover": "./cover.jpg",
  "coverCredit": "Raphaël Biscaldi",
  "coverWebsite": "Unsplash",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this post, I will show you how to create a full React and Electron app, test, and build it.`}</p>
    <h3>{`Summary`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#react-app-setup"
        }}>{`React app setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#electron-app-setup"
        }}>{`Electron app setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#integrating-both"
        }}>{`Integrating both`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#communication-between-react-and-electron"
        }}>{`Communication between React and Electron`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testing-the-ipcrenderer-with-jest"
        }}>{`Testing the ipcRenderer with Jest`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-a-script-to-start-both-electron-and-react-simultaneously"
        }}>{`Adding a script to start both Electron and React simultaneously`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#building-for-cross-platform"
        }}>{`Building for cross-platform`}</a></li>
    </ul>
    <h2>{`Introduction`}</h2>
    <p>{`Electron is a framework to create cross-platform desktop apps. Ages ago it was mostly being used with plain js and html. Now we can integrate it with React. But I found it kinda troublefull, so I wrote this post to help you create a nice integration between these two great technologies.`}</p>
    <p>{`If you've never heard of Electron, I guess I can make a small tour.`}</p>
    <ol>
      <li parentName="ol">{`Electron uses Node.js for the back-end development;`}</li>
      <li parentName="ol">{`Electron uses `}<a parentName="li" {...{
          "href": "https://www.chromium.org/Home"
        }}>{`Chromium`}</a>{` for displaying web content;`}</li>
      <li parentName="ol">{`It also has some custom API's to deal with OS functions.`}</li>
    </ol>
    <p>{`There are a few reasons to use Electron.`}</p>
    <ol>
      <li parentName="ol">{`To use desktop features (obviously);`}</li>
      <li parentName="ol">{`To create cross-platform desktop apps more easily (compared to native technologies);`}</li>
      <li parentName="ol">{`Depending on the app, you can process stuff on the user-side, instead of using your server resources;`}</li>
      <li parentName="ol">{`Some apps generally work better as a desktop version, for me at least.`}</li>
    </ol>
    <p>{`Some popular apps developed with Electron are.`}</p>
    <ol>
      <li parentName="ol">{`VScode;`}</li>
      <li parentName="ol">{`Slack;`}</li>
      <li parentName="ol">{`Whatsapp.`}</li>
    </ol>
    <h2>{`React app setup`}</h2>
    <p>{`This isn't the scope of the post, so we will use the simplest possible CRA project.`}</p>
    <p>{`You can create the project using `}<inlineCode parentName="p">{`$ yarn create react-app my-app`}</inlineCode>{` or `}<inlineCode parentName="p">{`$ npm init react-app my-app`}</inlineCode>{`. If you have already created a folder, just replace "my-app" with a dot (current folder).`}</p>
    <p>{`We will also change the folder structure, since it's going to have the electron app in the same project, it would be a mess of files.`}</p>
    <p>{`Create a `}<inlineCode parentName="p">{`client`}</inlineCode>{` (or any name you want) folder in the `}<inlineCode parentName="p">{`src`}</inlineCode>{` folder, move everything except the `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`setupTests.js`}</inlineCode>{` (if you're using CRA) to it. Remember to also correct the imports.`}</p>
    <p>{`That's it for now for the React structure, we will be back to it shortly.`}</p>
    <hr></hr>
    <h2>{`Electron app setup`}</h2>
    <p>{`Now we need to create the Electron part of the app.`}</p>
    <p>{`First, create a folder, outside of the `}<inlineCode parentName="p">{`src`}</inlineCode>{` folder, named `}<inlineCode parentName="p">{`electron`}</inlineCode>{`, and a `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file inside it. Change the `}<inlineCode parentName="p">{`main`}</inlineCode>{` property in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` to `}<inlineCode parentName="p">{`electron/main.js`}</inlineCode>{`. This will define the main entry point for the app.`}</p>
    <p>{`After that, add the Electron package to the project, running `}<inlineCode parentName="p">{`$ yarn add electron --dev`}</inlineCode>{` and a start script to your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`. In the start script, we also add the environment variable containing the React app url, to access later.`}</p>
    <h4>{`package.json`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
"main": "electron/main.js",
"scripts": {
    "start:electron": "ELECTRON_START_URL=http://localhost:3000 electron ."
  }
...
`}</code></pre>
    <p>{`Lastly, the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` content. I will be using the Electron default code, found on their `}<a parentName="p" {...{
        "href": "https://www.electronjs.org/docs/tutorial/first-app"
      }}>{`documentation`}</a>{`. With just some small tweaks.`}</p>
    <h4>{`electron/main.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { app, BrowserWindow } = require('electron');
const path = require('path');

// Keep a global reference of the window object, if you don't, the window will
// be closed automatically when the JavaScript object is garbage collected.
let window = null;

function createWindow() {
  // Create the browser window.
  window = new BrowserWindow({
    width: 800,
    height: 600,
    webPreferences: {
      nodeIntegration: true,
    },
  });

  // Load the index.html of the app.
  // This will give an error for now, since we will be using a React app instead of a file.
  window.loadFile('index.html');

  // Open the DevTools.
  window.webContents.openDevTools({ mode: 'detach' });
}

app.on('ready', () => {
  createWindow();
});

app.on('window-all-closed', () => {
  if (process.platform !== 'darwin') {
    app.quit();
  }
});

app.on('activate', () => {
  // On macOS it's common to re-create a window in the app when the
  // dock icon is clicked and there are no other windows open.
  if (BrowserWindow.getAllWindows().length === 0) {
    createWindow();
  }
});
`}</code></pre>
    <p>{`This file is where all the electron code will be. Here, we are creating a window after the app is ready, and adding some functionality, like being able to close the window.`}</p>
    <p>{`Running `}<inlineCode parentName="p">{`$ yarn start:electron`}</inlineCode>{` should open up a blank window, and the devTools.`}</p>
    <hr></hr>
    <h2>{`Integrating both`}</h2>
    <p>{`It's time to show the React app inside the Electron desktop window. For that, it's quite simple, we just render the url instead of the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file directly.`}</p>
    <p>{`So, we need to change the `}<inlineCode parentName="p">{`createWindow`}</inlineCode>{` function to load the url.`}</p>
    <h4>{`electron/main.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const createWindow = () => {
  // Here, we are grabbing the React url from the env (which is on the start script)
  const startUrl = process.env.ELECTRON_START_URL;

  window = new BrowserWindow({
    width: 800,
    height: 600,
    webPreferences: {
      nodeIntegration: true,
    },
  });

  // And loading it in the window
  window.loadURL(startUrl);
  window.show();
  window.webContents.openDevTools({ mode: 'detach' });
};
`}</code></pre>
    <p>{`Now your Electron app should load the React app in the window, try it by running `}<inlineCode parentName="p">{`$ yarn start`}</inlineCode>{` (I renamed it to `}<inlineCode parentName="p">{`start:client`}</inlineCode>{`) to start the React app, and `}<inlineCode parentName="p">{`$ yarn start:electron`}</inlineCode>{` again to show the window.`}</p>
    <hr></hr>
    <h2>{`Communication between React and Electron`}</h2>
    <p>{`Now, another thing left to do, is to learn how to communicate between them. But you may ask, "what do you mean by communication"?, what can we do between Electron and React? The main.js file (the Electron part), is a node application, so you could do anything "back-end" related. You could fetch a database, add a notification system, use the local file-system, and so on. Technically you can use Node APIs inside the Renderer process also, but I don't think that's a good practice.`}</p>
    <p>{`Electron provides a way to communicate between the main and the renderer (React) process using modules called `}<a parentName="p" {...{
        "href": "https://www.electronjs.org/docs/api/ipc-main"
      }}>{`ipcMain`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.electronjs.org/docs/api/ipc-renderer"
      }}>{`ipcRenderer`}</a>{`.`}</p>
    <p>{`Both have a few functions, like `}<inlineCode parentName="p">{`send`}</inlineCode>{` and `}<inlineCode parentName="p">{`on`}</inlineCode>{`, who are used for asynchronous communication. And `}<inlineCode parentName="p">{`handle`}</inlineCode>{` (ipcMain) and `}<inlineCode parentName="p">{`invoke`}</inlineCode>{` (ipcRenderer) for synchronous communication.`}</p>
    <p>{`For the sake of simplicity, we will just send a simple object from one to another, but you could do anything.`}</p>
    <p>{`Let's look at it step by step.`}</p>
    <p><strong parentName="p">{`1. Defining the channel names`}</strong></p>
    <p>{`Create a `}<inlineCode parentName="p">{`shared`}</inlineCode>{` folder, inside the `}<inlineCode parentName="p">{`src`}</inlineCode>{` folder. In there, is where it's gonna have the code that will be used inside the main and renderer process. So, let's also create a `}<inlineCode parentName="p">{`constants.js`}</inlineCode>{` file inside it, where we define our message names.`}</p>
    <h4>{`src/shared/constants.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  channels: {
    GET_DATA: 'get_data',
  },
};
`}</code></pre>
    <p>{`As you can notice, we are using `}<inlineCode parentName="p">{`module.exports`}</inlineCode>{` in there, since we are going to use it in electron also.`}</p>
    <p><strong parentName="p">{`2. Creating the action that will send the message to Electron main process`}</strong></p>
    <p>{`In the React app, we will change some css, just to open up space. (you can skip this part if you are using it in your own app, obviously). I just deleted some css code, added a button and h3 style, and decentralized the logo vertically.`}</p>
    <h4>{`App.css`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  color: #fff;
}

input {
  margin-top: 16px;
  padding: 8px;
}

h3 {
  margin-left: 16px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header button {
  border: none;
  padding: 10px 20px;
  background: #777;
  color: #fff;
  z-index: 1;
  margin-top: 16px;
  cursor: pointer;
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` file, we will add the button, and the function to send the message.`}</p>
    <h4>{`App.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import logo from './logo.svg';
import './App.css';
import { channels } from '../shared/constants';

const { ipcRenderer } = window.require('electron');

function App() {
  const getData = () => {
    ipcRenderer.send(channels.GET_DATA, { product: 'notebook' });
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width={200} className="App-logo" alt="logo" />
        <button onClick={getData}>Get data</button>
      </header>
    </div>
  );
}

export default App;
`}</code></pre>
    <p>{`We need to import the `}<inlineCode parentName="p">{`ipcRenderer`}</inlineCode>{` module using `}<inlineCode parentName="p">{`window.require`}</inlineCode>{`, because we want to require the electron during runtime from the node environment, rather than the one used during compilation by webpack. link to the github issue `}<a parentName="p" {...{
        "href": "https://github.com/electron/electron/issues/7300"
      }}>{`here`}</a>{`.`}</p>
    <p>{`In this file, we import the `}<inlineCode parentName="p">{`ipcRenderer`}</inlineCode>{`, and the channels we've created in the constants file. And create a `}<inlineCode parentName="p">{`getData`}</inlineCode>{` function, that will be triggered by clicking on the button.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`send`}</inlineCode>{` method from the `}<inlineCode parentName="p">{`ipcRenderer`}</inlineCode>{`, receives the channel name as the first parameter, and a optional data that will be sent to the main process.`}</p>
    <p><strong parentName="p">{`3. Receiving the data in the main process`}</strong></p>
    <p>{`At the end of the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file, we need to listen to the `}<inlineCode parentName="p">{`get_data`}</inlineCode>{` event, using the `}<inlineCode parentName="p">{`ipcMain`}</inlineCode>{` module.`}</p>
    <h4>{`electron/main.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { app, BrowserWindow, ipcMain } = require('electron');
const { channels } = require('../src/shared/constants');

...

// End of the file
ipcMain.on(channels.GET_DATA, (event, arg) => {
  const { product } = arg;
  console.log(product);
});
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`ipcMain.on`}</inlineCode>{` method, also receives the channel as the first parameter, and a function as the second, that function has an `}<inlineCode parentName="p">{`event`}</inlineCode>{`, and an `}<inlineCode parentName="p">{`arg`}</inlineCode>{` arguments. The `}<inlineCode parentName="p">{`arg`}</inlineCode>{` is the data we've sent from the renderer process.`}</p>
    <p>{`Restart the Electron app, and click the `}<inlineCode parentName="p">{`Get data`}</inlineCode>{` button, you should see the data print in the server console.`}</p>
    <p><strong parentName="p">{`4. Sending a response back to the renderer process`}</strong></p>
    <p>{`Now we will mock a database call (or a file system), by just returning an object to the renderer process.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const products = {
  notebook: {
    name: 'notebook',
    price: '2500',
    color: 'gray',
  },
  headphone: {
    name: 'headphone',
    price: '700',
    color: 'black',
  },
};

// End of the file
ipcMain.on(channels.GET_DATA, (event, arg) => {
  const { product } = arg;
  event.sender.send(channels.GET_DATA, products[product]);
});
`}</code></pre>
    <p>{`This will send an event back to the renderer process, for the `}<inlineCode parentName="p">{`get_data`}</inlineCode>{` channel.`}</p>
    <p>{`We also need to change our React app to listen for the event, and show it on screen. We use the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hook, to listen to the `}<inlineCode parentName="p">{`get_data`}</inlineCode>{` channel. After the response is sent from the main process, it will get that data, and set the state, which will fill the information about the product.`}</p>
    <p>{`Here is the full file.`}</p>
    <h4>{`App.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { channels } from '../shared/constants';

const { ipcRenderer } = window.require('electron');

function App() {
  const [product, setProduct] = useState('');
  const [data, setData] = useState(null);

  const getData = () => {
    // Send the event to get the data
    ipcRenderer.send(channels.GET_DATA, { product });
  };

  useEffect(() => {
    // Listen for the event
    ipcRenderer.on(channels.GET_DATA, (event, arg) => {
      setData(arg);
    });

    // Clean the listener after the component is dismounted
    return () => {
      ipcRenderer.removeAllListeners();
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width={200} className="App-logo" alt="logo" />
        <input
          onChange={(e) => setProduct(e.target.value)}
          placeholder="Product name"
        />
        <button onClick={getData}>Search</button>
      </header>

      {data && (
        <>
          <h3>Product info</h3>
          <ul>
            <li>Name: {data.name}</li>
            <li>Price: {data.price}</li>
            <li>Color: {data.color}</li>
          </ul>
        </>
      )}
    </div>
  );
}

export default App;
`}</code></pre>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "795px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.09302325581395%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAcuklgH/xAAXEAADAQAAAAAAAAAAAAAAAAAAAhIg/9oACAEBAAEFAh5z/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEREiD/2gAIAQEABj8CFWc//8QAGRAAAwADAAAAAAAAAAAAAAAAAREhACBB/9oACAEBAAE/IUjcIqFOha//2gAMAwEAAgADAAAAEAAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQADAQEAAAAAAAAAAAAAAREQMVEAof/aAAgBAQABPxBglB5zxVIQxHx3hV23P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Electron App",
          "title": "Electron app",
          "src": "/static/ccee244180930865965a23f175cdf46b/dc4d4/electron_1.jpg",
          "srcSet": ["/static/ccee244180930865965a23f175cdf46b/53f89/electron_1.jpg 258w", "/static/ccee244180930865965a23f175cdf46b/b6e7a/electron_1.jpg 516w", "/static/ccee244180930865965a23f175cdf46b/dc4d4/electron_1.jpg 795w"],
          "sizes": "(max-width: 795px) 100vw, 795px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`It seems complicated, but it's quite simple, I'm gonna summarize it for you below.`}</p>
    <ol>
      <li parentName="ol">{`In the React app, we add the `}<inlineCode parentName="li">{`ipcRenderer.send`}</inlineCode>{` on an action (or in another way), to notify Electron about something we need, and also add a listener to listen for the respective response using `}<inlineCode parentName="li">{`ipcRenderer.on`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`main`}</inlineCode>{` process, we add the listener to listen for the renderer process (React app) events, using `}<inlineCode parentName="li">{`ipcMain.on`}</inlineCode>{`, and we can send back the response it is waiting for using `}<inlineCode parentName="li">{`event.sender.send`}</inlineCode>{`.`}</li>
    </ol>
    <h3>{`Isn't it better to just use an api?`}</h3>
    <p>{`Depends, I've used this example here because it's simple, but the main focus is the communication, you can do anything with it. One thing for example that we could do, that is more useful, is an option to quit the app.`}</p>
    <h4>{`App.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`...

const handleQuit = () => {
  ipcRenderer.invoke(channels.QUIT);
};

return (
  <nav>
    <button>Config</button>
    <button onClick={handleQuit}>Quit app</button>
  </nav>
);

`}</code></pre>
    <h4>{`electron/main.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...

ipcMain.handle(channels.QUIT, () => {
  app.quit();
});
`}</code></pre>
    <hr></hr>
    <h2>{`Testing the ipcRenderer with Jest`}</h2>
    <p>{`It took me a few hours to find a way to test it properly, and I don't think I found the perfect way for it, there are some questions on Stackoverflow about it, but it was complicated for me to understand and implement, so I'm gonna just comment on how I tested my Electron apps, even if it isn't the best way to do it.`}</p>
    <p>{`I'm gonna use the app I created in this post as an example.`}</p>
    <p>{`First, we need to setup our tests initialization config. Because we use `}<inlineCode parentName="p">{`window.require`}</inlineCode>{` to require electron, the tests will scream about it.
To fix that, I'm gonna add this single-line config in the `}<inlineCode parentName="p">{`setupTests.js`}</inlineCode>{` file that `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` makes available.`}</p>
    <h4>{`src/setupTests.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.require = require;
`}</code></pre>
    <p>{`If you're using just Jest, you can add the same config in the `}<inlineCode parentName="p">{`jest.setup.js`}</inlineCode>{`, and add the `}<inlineCode parentName="p">{`setupFilesAfterEnv`}</inlineCode>{` in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` or `}<inlineCode parentName="p">{`jest.config.js`}</inlineCode>{`, `}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/en/configuration#setupfilesafterenv-array"
      }}>{`like that`}</a>{`.`}</p>
    <p>{`Now, we need to mock the `}<inlineCode parentName="p">{`ipcRenderer`}</inlineCode>{` used in the React app. In the same file (`}<inlineCode parentName="p">{`setupTests`}</inlineCode>{` or `}<inlineCode parentName="p">{`jest.setup.js`}</inlineCode>{`), we can use the `}<inlineCode parentName="p">{`jest.mock`}</inlineCode>{` to mock the electron package. This function takes the module name, and a factory function (a function that returns an object) containing the module properties we want to mock.`}</p>
    <h4>{`src/setupTests.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.require = require;

jest.mock('electron', () => {
  return {
    ipcRenderer: {
      on: jest.fn(),
      send: jest.fn(),
      removeAllListeners: jest.fn(),
    },
  };
});
`}</code></pre>
    <p>{`We can now start testing without initial errors. I'm gonna show you the full test file, and then explain some sections later.`}</p>
    <h4>{`index.test.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { act } from 'react-dom/test-utils';
import { channels } from '../../shared/constants';
import App from '../App';

const { ipcRenderer } = require('electron');

describe('App component', () => {
  it('Should search for a product after clicking search', () => {
    render(<App />);
    const input = screen.getByRole('textbox');
    const searchButton = screen.getByRole('button');
    const product = 'notebook';

    userEvent.type(input, product);
    userEvent.click(searchButton);

    expect(ipcRenderer.send).toBeCalledWith(channels.GET_DATA, {
      product,
    });
  });

  it('Should render the search result on the page', () => {
    render(<App />);
    const mData = {
      name: 'notebook',
      price: '2500',
      color: 'gray',
    };

    act(() => {
      ipcRenderer.on.mock.calls[0][1](null, mData);
    });

    expect(ipcRenderer.on).toBeCalledWith(
      channels.GET_DATA,
      expect.any(Function)
    );

    expect(screen.getByText(/Name/).textContent).toEqual(\`Name: \${mData.name}\`);
    expect(screen.getByText(/Price/).textContent).toEqual(
      \`Price: \${mData.price}\`
    );
    expect(screen.getByText(/Color/).textContent).toEqual(
      \`Color: \${mData.color}\`
    );
  });
});
`}</code></pre>
    <p>{`Let's start with the first test`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`it('Should search for a product after clicking search', () => {
  // 1
  render(<App />);
  const input = screen.getByRole('textbox');
  const searchButton = screen.getByRole('button');
  const product = 'notebook';

  // 2
  userEvent.type(input, product);
  userEvent.click(searchButton);

  // 3
  expect(ipcRenderer.send).toBeCalledWith(channels.GET_DATA, {
    product,
  });
});
`}</code></pre>
    <ol>
      <li parentName="ol">{`Rendering the component that is being tested, getting the input and search button.`}</li>
      <li parentName="ol">{`Typing the product name on the input, and hitting search.`}</li>
      <li parentName="ol">{`Testing if the `}<inlineCode parentName="li">{`ipcRenderer.send`}</inlineCode>{` is being called with the right channel and product name typed in the input, after hitting search.`}</li>
    </ol>
    <p>{`Now, the second test. This one is more tricky, since we need to also mock the `}<inlineCode parentName="p">{`on`}</inlineCode>{` method call, and execute it's callback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`it('Should render the search result on the page', () => {
  // 1
  render(<App />);
  const mData = {
    name: 'notebook',
    price: '2500',
    color: 'gray',
  };

  // 2
  act(() => {
    ipcRenderer.on.mock.calls[0][1](null, mData);
  });

  // 3
  expect(ipcRenderer.on).toBeCalledWith(
    channels.GET_DATA,
    expect.any(Function)
  );

  // 4
  expect(screen.getByText(/Name/).textContent).toEqual(\`Name: \${mData.name}\`);
  expect(screen.getByText(/Price/).textContent).toEqual(
    \`Price: \${mData.price}\`
  );
  expect(screen.getByText(/Color/).textContent).toEqual(
    \`Color: \${mData.color}\`
  );
});
`}</code></pre>
    <ol>
      <li parentName="ol">{`Rendering the component that is being tested, and creating an object to mock the returned data.`}</li>
      <li parentName="ol">{`In the App component, the `}<inlineCode parentName="li">{`ipcRenderer.on`}</inlineCode>{` is called on the `}<inlineCode parentName="li">{`useEffect`}</inlineCode>{` hook, so we can already access its properties, `}<inlineCode parentName="li">{`ipcRenderer.on.mock.calls`}</inlineCode>{` is accessing all the calls made, it returns something like this `}<inlineCode parentName="li">{`[ [ 'get_data', [Function] ] ]`}</inlineCode>{`, which is the arguments passed to it, including the callback, in this case, I'm getting that callback and firing it, that way, it will also call the `}<inlineCode parentName="li">{`setData`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Testing if the `}<inlineCode parentName="li">{`ipcRenderer.on`}</inlineCode>{` is being called with the right channel and a callback function.`}</li>
      <li parentName="ol">{`Testing if the product information is rendered correctly on the page, and with the right data.`}</li>
    </ol>
    <p>{`I hope it's all clear. Another thing it's important to say, is that, technically, we shouldn't test implementations, only the UI like it's the user who is using the app. But with Electron, it's hard to test like that, since we have that communication between them.`}</p>
    <hr></hr>
    <h2>{`Adding a script to start both Electron and React simultaneously`}</h2>
    <p>{`As you noticed, we need to start both React (with `}<inlineCode parentName="p">{`yarn start:client`}</inlineCode>{`) and Electron (with `}<inlineCode parentName="p">{`yarn start:electron`}</inlineCode>{`). And, we need to always start the React app first.
We can do something to solve that, and make it easier for someone to start the app.`}</p>
    <p>{`Run `}<inlineCode parentName="p">{`$ yarn add --dev concurrently wait-on cross-env`}</inlineCode>{`, to install those required dependencies. Then, change your start script in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file.`}</p>
    <h4>{`package.json`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
  "start": "concurrently  \\"cross-env BROWSER=none PORT=3000 react-scripts start\\" \\"wait-on http://localhost:3000 && ELECTRON_START_URL=http://localhost:3000 electron .\\""
  ...
}
`}</code></pre>
    <p>{`We use the `}<inlineCode parentName="p">{`concurrently`}</inlineCode>{` to run two scripts at once, the `}<inlineCode parentName="p">{`wait-on`}</inlineCode>{` to wait for the React app to load, and the `}<inlineCode parentName="p">{`cross-env`}</inlineCode>{` is used to being able to use the same env for any platform, in one command.`}</p>
    <p>{`That's it, now you only need to run one command to start your app.`}</p>
    <h2>{`Building for cross-platform`}</h2>
    <p>{`Electron most powerful feature is being able to build for cross-platform easily. But, not so much with React, it's more verbose and needs some weird configuration.`}</p>
    <p>{`I'll start by installing the library `}<a parentName="p" {...{
        "href": "https://www.electron.build/"
      }}>{`electron-builder`}</a>{`. And back again with the step-by-step process.`}</p>
    <p><strong parentName="p">{`1. Configuring the package.json`}</strong></p>
    <p>{`We need to add two new properties to the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file, `}<inlineCode parentName="p">{`build`}</inlineCode>{` and `}<inlineCode parentName="p">{`homepage`}</inlineCode>{`. `}<inlineCode parentName="p">{`build`}</inlineCode>{` have some general configuration, and `}<inlineCode parentName="p">{`homepage`}</inlineCode>{` is the path for the built app to be served from a subdirectory, React sets the root path based on this setting.`}</p>
    <h4>{`package.json`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
"homepage": "./",
"build": {
    "productName": "Sample App",
    "appId": "com.company.sample-app",
    "files": [
      "build/**/*",
      "node_modules/**/*",
      "dist/",
      "package.json"
    ],
    "directories": {
      "output": "release"
    }
  }
`}</code></pre>
    <p><strong parentName="p">{`productName:`}</strong>{` The name that will be shown on the executable.
`}<strong parentName="p">{`appId:`}</strong>{` Necessary if building for mac.
`}<strong parentName="p">{`files:`}</strong>{` Specifies which resource files to include when creating the package.
`}<strong parentName="p">{`directories:`}</strong>{`The platform executable output folder.`}</p>
    <p>{`We also need to add the packing scripts, to build React, Electron, and use `}<inlineCode parentName="p">{`electron-builder`}</inlineCode>{` to build it.`}</p>
    <h4>{`package.json`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
    "build:client": "react-scripts build",
    "build:electron": "rm -rf build/src build/shared && mkdir build/src && cp -r electron/. build/electron && cp -r src/shared/. build/src/shared",
    "build:electron-win": "rm -rf build/src build/shared && mkdir build/src && robocopy electron build/electron /S & robocopy src/shared build/src/shared /S",
    "pack:linux": "electron-builder -c.extraMetadata.main=build/electron/main.js --publish never",
    "pack:windows": "electron-builder --win -c.extraMetadata.main=build/electron/main.js --publish never",
  },
`}</code></pre>
    <p><strong parentName="p">{`build:client:`}</strong>{` Build the React app normally.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`build:electron:`}</strong>{` Here, I'm copying the `}<inlineCode parentName="p">{`electron`}</inlineCode>{` and the `}<inlineCode parentName="p">{`src/shared`}</inlineCode>{` folder to the build folder. We do that because we need to access this from the `}<inlineCode parentName="p">{`react-builder`}</inlineCode>{` later'.
`}<strong parentName="p">{`build:electron-win:`}</strong>{` The same as the `}<inlineCode parentName="p">{`build:electron`}</inlineCode>{`, except that it's a script for windows.`}</p>
    <p><strong parentName="p">{`pack:linux:`}</strong>{` Executing `}<inlineCode parentName="p">{`electron-builder`}</inlineCode>{` to build electron. Here I needed to use the `}<inlineCode parentName="p">{`-c.extraMetadata.main`}</inlineCode>{` property, to change the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` `}<inlineCode parentName="p">{`main`}</inlineCode>{` property to `}<inlineCode parentName="p">{`build/electron/main.js`}</inlineCode>{`, to point to the correct build files which we moved in the script before.
`}<strong parentName="p">{`pack:windows:`}</strong>{` The same as the `}<inlineCode parentName="p">{`pack:linux`}</inlineCode>{`, except that it's to build for windows. Notice the `}<inlineCode parentName="p">{`--win`}</inlineCode>{` flag.`}</p>
    <p><strong parentName="p">{`2. Rendering the compiled react code for production`}</strong></p>
    <p>{`For running the project locally, we render the React app url into Electron, but for production, we will use the compiled react app, which is the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file located in the build folder.`}</p>
    <p>{`It's just some adjustments in the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file.`}</p>
    <h4>{`electron/main.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const url = require('url');
const createWindow = () => {
  const startUrl =
    process.env.ELECTRON_START_URL ||
    url.format({
      pathname: path.join(__dirname, '../index.html'),
      protocol: 'file:',
      slashes: true,
    });

  window = new BrowserWindow({
    width: 800,
    height: 600,
    webPreferences: {
      nodeIntegration: true,
    },
  });

  window.loadURL(startUrl);
  window.show();
  window.webContents.openDevTools({ mode: 'detach' });
};
`}</code></pre>
    <p>{`As you can see, I just added the index.html path when there is no `}<inlineCode parentName="p">{`ELECTRON_START_URL`}</inlineCode>{` env. The `}<inlineCode parentName="p">{`path.join(__dirname, '../index.html')`}</inlineCode>{` grabs the index.html path, because the main entry point (the `}<inlineCode parentName="p">{`main`}</inlineCode>{` property from `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`) is `}<inlineCode parentName="p">{`build/electron/main.js`}</inlineCode>{` file, doing a `}<inlineCode parentName="p">{`../index.html`}</inlineCode>{` will get it from the build folder.`}</p>
    <p><strong parentName="p">{`3. Creating the executable`}</strong>{`
To create the executable, I choose to use Docker. The reason, is that we need different setups for each operating system without it. For example, Linux building for windows would require you to install Wine.`}</p>
    <h4>{`Building for Linux`}</h4>
    <p>{`Just run this (very big) docker script, and it will run this docker image in your local machine.`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run --rm -ti \\
 --env-file <(env | grep -iE 'DEBUG|NODE_|ELECTRON_') \\
 --env ELECTRON_CACHE="/root/.cache/electron" \\
 --env ELECTRON_BUILDER_CACHE="/root/.cache/electron-builder" \\
 -v \${PWD}:/project \\
 -v \${PWD##*/}-node-modules:/project/node_modules \\
 -v ~/.cache/electron:/root/.cache/electron \\
 -v ~/.cache/electron-builder:/root/.cache/electron-builder \\
 electronuserland/builder
`}</code></pre>
    <p>{`Inside Docker, run `}<inlineCode parentName="p">{`$ yarn && yarn pack:linux`}</inlineCode>{`.`}<br parentName="p"></br>{`
`}{`After that, a `}<inlineCode parentName="p">{`release`}</inlineCode>{` folder (which is the folder we defined in the `}<inlineCode parentName="p">{`build`}</inlineCode>{` property inside `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`) will be created in the project root, which will have the app in `}<inlineCode parentName="p">{`AppImage`}</inlineCode>{` or `}<inlineCode parentName="p">{`.deb`}</inlineCode>{` format for Linux.`}</p>
    <h4>{`Building for Windows`}</h4>
    <p>{`It's almost the same thing, except that we need another image, for Linux we are using `}<inlineCode parentName="p">{`electronuserland/builder`}</inlineCode>{`, and for Windows we need the `}<inlineCode parentName="p">{`electronuserland/builder:wine`}</inlineCode>{`, which has Wine installed within it.`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run --rm -ti \\
 --env-file <(env | grep -iE 'DEBUG|NODE_|ELECTRON_') \\
 --env ELECTRON_CACHE="/root/.cache/electron" \\
 --env ELECTRON_BUILDER_CACHE="/root/.cache/electron-builder" \\
 -v \${PWD}:/project \\
 -v \${PWD##*/}-node-modules:/project/node_modules \\
 -v ~/.cache/electron:/root/.cache/electron \\
 -v ~/.cache/electron-builder:/root/.cache/electron-builder \\
 electronuserland/builder:wine
`}</code></pre>
    <p>{`Inside Docker, run `}<inlineCode parentName="p">{`$ yarn && yarn pack:windows`}</inlineCode>{`.`}<br parentName="p"></br>{`
`}{`The same `}<inlineCode parentName="p">{`release`}</inlineCode>{` folder will be created, but with Windows .exe executable.`}</p>
    <p>{`You can check out more about building with Docker for other platforms in the `}<a parentName="p" {...{
        "href": "https://www.electron.build/multi-platform-build#docker"
      }}>{`electron-builder documentation`}</a>{`.`}</p>
    <hr></hr>
    <h2>{`Conclusion`}</h2>
    <p>{`If everything occurred right, you should have a working Electron + React app to share!`}</p>
    <p>{`As you can see, in the end, it's not that complicated, but also there is a lot to learn and implement to not have a headache working with Electron and React. I hope it was useful for you. I will update this post if some change that could break the app happens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      